.App {
  background-color: #00184e;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*components*/
.lined-paper {
  background-color: #fff; /* Background color of the paper */
  background-image: linear-gradient(to bottom, #ffffff 95%, #d1d1d1 96%); /* Gray horizontal lines */
  background-size: 100% 24px; /* Spacing between the lines */
  padding: 20px; /* Padding inside the paper */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 100;
}
.float-right {
  float: right;
}
.border-radius {
  border-radius: 10px !important;
}
.bg-blue {
  background-color: #00184e;
}
.message-box {
  border-radius: 10px !important;
  background-color: whitesmoke;
  padding: 1em;
  margin-bottom: 1em;
}

/*Buttons*/
.primary-colour {
  background-color: #6d9ac6 !important;
  border: #6d9ac6 !important;
}
.logout-button {
  color: white;
  margin-right: 0em;
  cursor: pointer;
}

/*Header*/
.dm-serif-text-regular {
  font-family: "DM Serif Text", serif;
  font-weight: 400;
  font-style: normal;
}
.logo {
  font-size: 20pt;
  color: white;
  cursor: pointer;
}
.logo-motto {
  font-size: 10pt;
  font-weight: bold;
  margin-top: -10px;
  color: white;
  cursor: pointer;
}
.dm-serif-text-regular-italic {
  font-family: "DM Serif Text", serif;
  font-weight: 400;
  font-style: italic;
}
.itim-regular {
  font-family: "Itim", serif;
  font-weight: 400;
  font-style: normal;
}

/*Form*/
.form-element {
  border: solid;
  padding-top: 0.6em;
  padding-bottom: 0.6em;
  padding-left: 0.8em;
  padding-right: 0.8em;
  border-radius: 8px;
  margin-bottom: 0em;
  background-color: whitesmoke;
}
.form-row {
  margin-bottom: 0.5em;
}
.click-button {
  cursor: pointer;
  padding: 0.3em;
  border: solid white 1px;
  color: whitesmoke;
  border-radius: 5px;
  font-size: 1.1em;
}
.book-button {
  cursor: pointer;
  padding: 0.3em;
  border: solid black 1px;
  color: black;
  border-radius: 5px;
  font-size: 0.8em;
}

/*Loading*/
.trip-loading {
  padding: 1em;
  text-align: center;
}
.loading-wheel {
  display: inline-block;
  font-size: 3rem;
  color: #00184e;
}
.spin {
    animation: spin 3s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*Trip display*/
.day-bg {
  background-color: whitesmoke;
}
.title {
  font-size: large;
  font-style: normal;
  font-weight: 600;
}
.travel-details {
  border-radius: 10px;
  padding: 0.7em;
  background-color: #d0ffea;
  border: solid 1px lightgray;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2);
  font-size: 11pt;
}
.hotel {
  border-radius: 10px;
  padding: 0.7em;
  background-color: #faf3d7;
  border: solid 1px lightgray;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2);
  font-size: 11pt;
}
.activity {
  border-radius: 10px;
  padding: 0.7em;
  background-color: #daeaff;
  border: solid 1px lightgray;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2);
  font-size: 11pt;
}
.day-info {
  border-radius: 10px;
  padding: 0.5em;
  background-color: white;
  border: solid 1px lightgray;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2);
}
.restaurant {
  background-color: #fee7eb;
}
.list-bg {
  background-color: #ffffff63;
  padding: 0.6em;
  border-radius: 10px;
}
.bg-green {
  background-color: #1c7430;
}
.bg-orange {
  background-color: #fad100;
  color: black!important;
}
.bg-gray {
  background-color: #545454;
}
.bg-gray-2 {
  background-color: rgba(59, 59, 59, 0.58);
}
.bg-gold {
  background-color: #dca500;
}
.bg-budget {
  background-color: #1d7363;
}
.tag {
  color: white;
  padding: 0.3em 0.4em;
  border-radius: 8px;
  display: inline-block;
  font-size: 8pt;
  font-weight: bold;
  margin-right: 0.5em;
}
.unit-box {
  display: flex;
  align-items: center;
}
.time {
  margin-right: 0.4em; /* Space between time and text lines */
  display: flex;
  align-items: center; /* Vertically center the time text */
  height: 100%; /* Take the full height of the container */
  font-weight: 400;
  margin-left: -0.4em;
}
.time-no-flex {
  margin-right: 0.4em; /* Space between time and text lines */
  align-items: center; /* Vertically center the time text */
  height: 100%; /* Take the full height of the container */
  font-weight: 400;
  margin-left: -0.4em;
}
.text-lines {
  display: flex;
  flex-direction: column;
}
.time-border {
  border-left: solid 1px grey;
  padding-left: 0.4em;
}

/*Recent trips*/
.recent-trip {
  display: inline-block;
  background-color: whitesmoke;
  border-radius: 8px;
  margin-right: 0.5em;
  margin-bottom: 0.5em;
  padding: 0.4em;
  cursor: pointer;
}

/*Nav*/
.nav-link {
  color: white !important;
  padding: 0.2em !important;
  border-radius: 5px;
  border: solid 1px white !important;
  margin-right: 0.4em;
  font-size: 0.9em;
}
.nav-link.active {
  color:  #00184e !important;
  font-weight: bold;
  background-color: whitesmoke !important;
}
